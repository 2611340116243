import React from "react";
const { useState } = React;

const Sketch = React.lazy(() => import("react-p5"));

function SketchF({ worksData, bgImg, containerH, curentWork }) {
  const num = 18;
  let imgs = [];
  let works = [];
  let img;
  let bg;
  let showN = curentWork;
  let speed = 0;
  let speedTarget = 0;
  let rot = 0.5;
  let rotTarget = 0;
  let angle = 0;
  let n;
  let zoom = 1;
  let zoomTarget = 1;
  let inp;
  let pg;
  const [newBg, setNewBg] = useState();
  const [newImgs, setNewImgs] = useState([]);
  const setup = (p5, canvasParentRef) => {
    p5.createCanvas(p5.windowWidth, containerH, p5.WEBGL).parent(
      canvasParentRef
    );
    p5.background(255);

    n = newImgs.length;

    pg = p5.createGraphics(p5.windowWidth * 0.25, p5.windowWidth * 0.25);
    pg.pixelDensity(4);
    pg.textSize(p5.windowWidth * 0.004);
  };

  const draw = (p5) => {
    p5.translate(0, 50, 0);

    show(p5);

    worksShow(p5);

    var today = new Date(new Date().getTime()).toLocaleTimeString("pl", {
      timeZone: "Europe/Warsaw",
    });

    // pg.clear();
    // pg.fill(0, 0, 0);
    // pg.text(today, p5.windowWidth * 0.171, p5.windowWidth * 0.1565);
    p5.push();
    p5.rotateX(p5.PI / 2);
    p5.translate(0, 0, p5.windowWidth * -0.025);

    p5.texture(newBg);

    p5.plane(p5.windowWidth * 0.25, p5.windowWidth * 0.25);

    p5.pop();
    p5.push();
    p5.rotateX(p5.PI / 2);
    p5.translate(0, 0, p5.windowWidth * -0.025);
    // p5.texture(pg);
    p5.texture(newBg);

    p5.plane(p5.windowWidth * 0.25, p5.windowWidth * 0.25);

    p5.pop();
  };

  // const windowResized = (p5) => {
  //   vechicles = [];
  //   p5.resizeCanvas(p5.windowWidth, p5.windowHeight);
  //   for (var y = 0; y < p5.windowHeight + squareSize; y += squareSize) {
  //     for (var x = 0; x < p5.windowWidth; x += squareSize) {
  //       if (odd) {
  //         fillIt = true;
  //         odd = false;
  //       } else {
  //         fillIt = false;

  //         odd = true;
  //       }
  //       let vechicle = new Vechicle(x, y, squareSize, fillIt, p5);
  //       vechicles.push(vechicle);
  //     }
  //   }
  // };

  function show(p5) {
    p5.background(255);
    p5.ambientLight(100);
    p5.pointLight(180, 180, 180, 0, -20, 400);

    speedTarget = p5.map(p5.mouseX, 0, p5.width, -0.02, 0.02);

    speed = p5.lerp(speed, speedTarget, 0.05);
    angle += speed;
    rotTarget = p5.map(
      p5.constrain(p5.mouseY, 0, containerH),
      p5.height,
      0,
      0.1,
      -1
    );
    rot = p5.lerp(rot, rotTarget, 0.05);

    p5.rotateX(rot);
    p5.rotateY(angle);
    zoomTarget = p5.map(
      p5.constrain(p5.mouseY, 0, containerH),
      p5.height,
      0,
      1,
      2.5
    );
    zoom = p5.lerp(zoom, zoomTarget, 0.1);
    p5.scale(zoom);
  }

  function worksShow(p5) {
    p5.noStroke();

    // for (let i = 0; i < n; i++) {
    //   let ang = (i * p5.TWO_PI) / n;
    //   p5.push();
    //   p5.rotateY(ang);
    //   p5.translate(p5.width / 4, 0, 0);

    //   p5.translate(0, (p5.height * -1) / 20, 0);

    //   if (showN == i) {
    //     p5.tint(255, 255);
    //     p5.rotateY(p5.PI / 2);
    //   } else {
    //     p5.tint(255, 10);
    //   }
    //   if (showN < 0) {
    //     p5.tint(255, 255);
    //   }

    //   p5.texture(newImgs[i]);
    //   let wrokProportion = newImgs[i].width / newImgs[i].height;
    //   let workW = p5.width / 20;
    //   let workH = workW / wrokProportion;

    //   p5.plane(workW, workH);

    //   p5.pop();
    // }
    p5.texture(newImgs[0]);
    let wrokProportion = newImgs[0].width / newImgs[0].height;
    let workW = p5.width / 20;
    let workH = workW / wrokProportion;
    p5.plane(workW, workH);
  }

  function myInputEvent() {
    showN = this.value();
  }
  function preload(p5) {
    setNewBg(p5.loadImage(bgImg));
    worksData.map((pos, index) => {
      // setNewImgs(([index] = p5.loadImage(pos.node.Image.localFile.publicURL)));
      setNewImgs((newImgs) => [
        ...newImgs,
        p5.loadImage(pos.node.Image.localFile.publicURL),
      ]);
      // console.log(p5.loadImage(pos.node.Image.localFile.publicURL));
      // imgs[index] = p5.loadImage(pos.node.Image.localFile.publicURL);
    });
  }
  ///

  const isSSR = typeof window === "undefined";
  return (
    <>
      {!isSSR && (
        <React.Suspense fallback={<div>Loading...</div>}>
          {/* <Sketch setup={setup} draw={draw} windowResized={windowResized} />; */}
          <Sketch setup={setup} draw={draw} preload={preload} />;
          {/* <Sketch setup={setup} draw={draw} />; */}
        </React.Suspense>
      )}
    </>
  );
}

export default SketchF;
