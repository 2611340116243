import React, { useRef, useEffect, useState } from "react";
import { graphql } from "gatsby";

import styled from "styled-components";

import Sketch from "src/components/p5/SketchCopy.js";

const ContentWrapper = styled.div`
  height: 100vh;
`;

const P5 = styled.div`
  width: 100vw;
  z-index: -1;
  position: relative;
  flex-grow: 1;
`;

const ArtistsWrapper = styled.ul`
  width: calc(100% - 60px);
  padding: 30px;
  position: relative;
`;

const ArtistWrapper = styled.li`
  display: inline-flex;
  margin-right: 30px;
`;

const ArtistName = styled.h3`
  font-size: 16px;
  margin-bottom: 8px;
  font-family: "DM Serif Display", serif;
`;

const Artist = ({ artistName, onClick }) => {
  return <ArtistName onClick={onClick}>{artistName}</ArtistName>;
};

const IndexPage = ({ data }) => {
  const p5Container = useRef(null);
  const [p5ContainerH, setP5ContainerH] = useState(0);

  const [curentWork, setCurentWork] = useState(-1);
  useEffect(() => {
    const height = p5Container.current.offsetHeight;
    setP5ContainerH(height);
    console.log("rerender");
  }, []);

  const changeCurentWork = (cur) => {
    setCurentWork(cur);
    console.log(cur + "___index");
  };
  return (
    <>
      <ContentWrapper>
        <P5 ref={p5Container}>
          <Sketch
            // worksData={data.allStrapiArtistWork.edges}
            bgImg={data.strapiMainInfo.Bg_img.localFile.publicURL}
            containerH={p5ContainerH}
            // curentWork={curentWork}
          />
        </P5>
        <ArtistsWrapper>
          {data.allStrapiArtistWork.edges.map((pos, index) => (
            <Artist
              onClick={() => changeCurentWork(index)}
              artistName={pos.node.Name}
              work={pos.node.Work_name}
            />
          ))}
        </ArtistsWrapper>
      </ContentWrapper>
    </>
  );
};

export default IndexPage;

export const IndexQuery = graphql`
  query MyQuery1 {
    strapiMainInfo {
      Bg_img {
        localFile {
          publicURL
        }
      }
    }
    allStrapiArtistWork {
      edges {
        node {
          Name
          Work_name
          Image {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`;
